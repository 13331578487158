import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import img1 from 'img/help/what-is-a-session/whatisasession.svg';
import img2 from 'img/help/what-is-a-session/whatisasession-2.svg';
import img3 from 'img/help/what-is-a-session/expires.svg';

const Content = () => {
  return (
    <div>
      <p>A session is a set of the user’s interactions with a website within a timeframe. </p>
      <p>The session begins when a user visits the website and does one of these things:</p>
      <ul>
        <li>scrolls</li>
        <li>moves a cursor</li>
        <li>clicks something (on desktop)</li>
        <li>taps something (on mobile)</li>
      </ul>
      <p>
        One session can contain many clicks, scrolls, page views and other events. What’s more, one
        user can open multiple sessions, either within one day or over a longer time span.
      </p>
      <p>These events are recorded as a part of a session:</p>
      <ul>
        <li>mouse movements and clicks</li>
        <li>mobile touches and taps</li>
        <li>window resizing and scrolling</li>
        <li>focusing and blurring elements</li>
        <li>text selection</li>
        <li>
          input value changes (if{' '}
          <a
            href="https://livesession.io/help/how-to-record-keystrokes/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            keystroke logging
          </a>{' '}
          is enabled)
        </li>
        <li>CSS changes</li>
        <li>URL changes</li>
        <li>
          <a
            href="https://en.wikipedia.org/wiki/Document_Object_Model"
            target="_blank"
            rel="noopener noreferrer"
          >
            DOM
          </a>{' '}
          mutations
        </li>
      </ul>
      <p>
        When one session ends, another one can be started. By default, a{' '}
        <strong>session ends after 30 minutes of inactivity</strong>.
      </p>
      <p>
        Let’s say a user, John, arrives on your website at 11:25 and does one of the actions
        mentioned above. If he doesn’t do anything after that, the session will end automatically at
        11:55. If he continues to interact with the website and the last event takes place at 12:27,
        the session will end automatically at 12:57.{' '}
      </p>
      <Image
        src={img1}
        imgStyle={{ padding: '20px', boxShadow: 'none', border: 'none' }}
        imgModalStyle={{ boxShadow: 'none', border: 'none' }}
        cardStyle={{ boxShadow: 'none', border: 'none' }}
      />
      <p>
        The session is set to <strong>expire after 30 minutes of inactivity</strong>. This means
        that:
      </p>
      <ul>
        <li>
          <p>
            If John leaves the page open, goes for a coffee break and comes back{' '}
            <strong>29 minutes later</strong>, it stills <strong>counts as one session</strong> –
            just like he never left the website.
          </p>
          <Image
            src={img3}
            imgStyle={{ padding: '20px', boxShadow: 'none', border: 'none' }}
            imgModalStyle={{ boxShadow: 'none', border: 'none' }}
            cardStyle={{ boxShadow: 'none', border: 'none' }}
          />
        </li>
        <li>
          <p>
            If John leaves the page open, goes for a coffee break and comes back{' '}
            <strong>31 minutes later, a new session starts</strong>. The landing page of that
            session is the last page of the previous session.
          </p>
          <Image
            src={img2}
            imgStyle={{ padding: '20px', boxShadow: 'none', border: 'none' }}
            imgModalStyle={{ boxShadow: 'none', border: 'none' }}
            cardStyle={{ boxShadow: 'none', border: 'none' }}
          />
        </li>
      </ul>
    </div>
  );
};

const related = [
  {
    link: '/help/how-livesession-script-works/',
    title: 'How LiveSession tracking script works',
    description: 'Understand what our tracking code tracks',
  },
];

export const frontmatter = {
  metaTitle: 'What is a session?',
  metaDescription:
    'A session is a set of the user’s interactions with a website within a timeframe.',
  canonical: '/help/what-is-a-session/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
